<template>
  <div
    v-if="locales !== null"
    class="mr-2"
  >
    <b-img
      src="@/assets/images/logo/edit2.gif"
      height="32px"
      width="32px"
      alt="Icon editing"
    />
    <span
      v-if="locales=='vi'"
      class="text-primary"
    >Edit in Vietnamese</span>
    <span
      v-else-if="locales=='en'"
      class="text-primary"
    >Edit in English</span>
    <span
      v-else
      class="text-primary"
    >{{ locales }}</span>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
// import language from '@/libs/lang/lang'
import general from '@/mixins/index'

export default {
  components: {
    BImg,
  },
  mixins: [general],
  data() {
    return {
      locales: null,
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.query.lang': function () {
      this.loadLang()
    },
  },
  created() {
    this.loadLang()
  },
  methods: {
    loadLang() {
      this.locales = null
      if (this.$route.query.lang !== undefined) {
        this.locales = this.$route.query.lang
      }
    },
  },
}
</script>
