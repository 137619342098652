<template>
  <b-nav-item
    :href="`https://beta-editor.busodevelopers.com/auth/login?domain=${siteData.domain_name}&admin_token=${userData.access_token}`"
    target="_blank"
    class="custom-btn-a mr-2"
  >
    <feather-icon
      size="21"
      :icon="`Edit2Icon`"
    />
    Design Page
  </b-nav-item>
</template>

<script>
// import useAppConfig from '@core/app-config/useAppConfig'
// import { computed } from '@vue/composition-api'
import { BNavItem } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('token')),
      siteData: JSON.parse(localStorage.getItem('siteID')),
    }
  },
}
</script>
<style lang="sass" scoped>
.custom-btn-a
  background: linear-gradient(118deg, #53bfaf, rgba(83, 191, 175, 0.7))
  padding: 5px 5px
  border-radius: 4px
  a
    color: white!important
</style>
